import React from 'react'
import { Link } from 'react-router-dom'

function Cnavbar() {
    return (
        <div className='bg-pm'>
            <div className="c-navbar">
                <div className="information-area"></div>
                <div className="wrapper">
                    <div className="nav-items">
                        <div className="nav-item">
                            <Link to='/academy' className='logo-area flex-item'>
                                <div className="logo">
                                    <div className="logo-text">KA</div> 
                                </div>
                                <span>Kiosso Academy</span>
                            </Link>
                        </div>
                       <div className='d-none'>
                       <ul className="nav-item flex-item ">
                            <li className='active'><Link to="#">Cursos em destaque</Link></li> 
                            <li><Link to="#">Mentoria & Suporte</Link></li>
                            <li><Link to="#">Testemunhos</Link></li>
                        </ul>
                       </div>
                        <div className="nav-item ">
                            <Link to="#" className='d-none'>
                                <button className='btn'>conta de estudante</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cnavbar
