import React from 'react'
import { Link } from 'react-router-dom';

function Work() {
  const WorkData = [
    {
      job_company_name: 'Exportech Portugal',
      job_company_type: 'Fulltime',
      job_company_position: 'Frontend Developer',
      job_company_date: 'From  Aug 2024  till  Current date',
      job_company_description: <div>
        <li>
          Helping with the maintenance and restoration of exportech ecommerce, integrating
          technologies such as: Express.Js , WordPress, Node.js, Html, Sql, Javascript, React.js,
          Css, Rest APIS, resulting in a better user experience and attracting customers for the
          company.
        </li>
        <li>
          Creation of SAAS systems and websites in the sales and logistics sector for the company
          and company's customers implementing Stacks such as: React.js, Node.js, Java, Spring,
          Sass, Html, Css, Javascript, Next.js, Next Auth, Bootstrap, Mysql, MongoDB, Python
          flask, Rest APIS, Express.js, Git, Github. 
        </li>
      </div>
    },
    {
      job_company_name: 'Hightech-AIRER',
      job_company_type: 'Part-Time',
      job_company_position: 'Software developer',
      job_company_date: 'From May 2024  to Aug 2024',
      job_company_description: <div>
        <li>
          Built  HT-Market e-commerce website and application  for  the company and manage the website content , integrating :
          Javascript, React.js,  Css, Html, API, MVC, Mysql, React-Native,  AXIOS, Expo, Figma, Node.js,
          Tailwind Css, Google cloud plataform authentication services , this resulted in increasing customer insights by  43%.
        </li>
        <li>
          Redesign and developed company website integrating :
          React.js, Node.js, Html, Css, Express.js, Mysql, Bootstrap, Javascript.
        </li>
      </div>
    },
    {
      job_company_name: 'Eduallsys',
      job_company_type: 'Fulltime',
      job_company_position: 'Software engineer',
      job_company_date: 'From Sep 2023 to Dec 2023',
      job_company_description: <div>
        <li>
          Built an educational website with portals or sections like: Parents portal, Students portal, Jobs portal, Employees portal, Dashboard,
          Forums , integrating : React.js, Next.js, React-Native , Mysql, Node.js, Html, Css, Javascript, Bootstrap  Rest API, MVC, Expo.
        </li>
        <li>
          Managed and modularized development tasks, increasing team efficiency and coordination, which resulted in
          +60.000 users active per month and +80.000  dollar on annual revenue for the company.
        </li>
        <li>
          Mentored new hires on software development and website management, how to use the software.
        </li>
        <li>
          Implemented new features for a cloud-based Service, benefiting over 50,000 users.
        </li>
      </div>
    },
    {
      job_company_name: 'OWN',
      job_company_type: 'Fulltime',
      job_company_position: 'Software engineer',
      job_company_date: 'From Dec 2021 to  Nov 2023',
      job_company_description: <div>
        <li>Maintained company website using tools like Html 5, React.js, Node.js, MySql
          , Sql, Sockets, Next.js. This led to providing customers with easy to use
          navigation of the site.</li>
        <li>Researched and documented operating procedures and Managed, optimized, and updated existing systems and platforms.</li>
        <li>Designed and developed an inventory module for existing software, this led to providen extra customers to the company</li>
      </div>
    },

    {
      job_company_name: 'Dil',
      job_company_type: 'Internship',
      job_company_position: 'Software engineer',
      job_company_date: 'From Jun 2021 to  Oct 2021',
      job_company_description: <div>
        <li>Enhanced user authentication and security by implementing OAuth and JWT, securing user data across platforms. </li>
        <li>Actively participated in bi-weekly agile sprint planning, contributing to a 8% increase in team velocity. </li>
        <li>Developed resources for the financial module for a human resources platform integrating  : React.js, Node.js, MySql, Javascript, Html, Css. </li>
      </div>
    },
    {
      job_company_name: 'OWN',
      job_company_type: 'Internship',
      job_company_position: 'Software engineer',
      job_company_date: 'From Jul 2019 to Oct 2019',
      job_company_description: <div>
        <li>Helping creating simple features for websites and web applications.</li>
        <li>Participated in daily standups and collaborated with a team of  engineers and a project manager. </li>
      </div>
    },
  ];

  return (
    <div className='work-experience' id='work'>
      <div className="wrapper">
        <div className="space">
          <div> <h1><span>02.</span> Work experience</h1></div>
          <div> <Link to="/resume"><div className="link">Open Resume</div></Link></div>
        </div>
        <div className="work-row">
          {WorkData.map((item, index) => {
            return (
              <>
                <div className={`work-row-item ${(index % 2 === 1) ? 'reverse' : ''} `} >
                  <div className="company-box text-center">
                    <h3>{item.job_company_name}</h3>
                    <p>{item.job_company_type}</p>
                  </div>
                  <div className="text-data">
                    <h2>{item.job_company_position}</h2>
                    <div className="date">{item.job_company_date} </div>
                    <p>{item.job_company_description}</p>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Work
