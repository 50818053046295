import React from 'react'
import { FaDownload } from 'react-icons/fa'
import Navbar from './Components/Navbar';
const file = require("./Docs/RESUME.pdf");
const file2 = require("./Docs/CV.pdf");

function Resume() {
  return (
    <div className='resume-container'>
       <Navbar bg />
      <div className='resume'> 
          <div className='block'>
          <a href={file} download >
              <button className="btn  btn-downlaod"><FaDownload/> Download - English Version</button>
          </a> 
          </div>
      </div>
    </div>
  )
}

export default Resume
