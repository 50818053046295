import React from 'react'
import Cnavbar from './components/Cnavbar';
import ImageLoading from '../Components/ImageLoading';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

function Courses() {
  document.title = "Kiosso Academy";
  const Data = [
      {
        status:1,
        name:"Portofolio Design com Html, Css, Javascript, Bootstrap",
        image:"https://cdn.dribbble.com/users/9295020/screenshots/17749182/media/78c6c976c51181bd34d4b086499acfca.png?resize=768x576&vertical=center",
        id:1,
        price:null,
        level:1,
      },
      {
        status:0,
        name:"BeliBeli ecommerce com React.js, Node.js, MongoDB, Socket.io  ...",
        image:"https://cdn.dribbble.com/userupload/9000437/file/original-05ce7878ecc282d0bdbc476f002b6087.png?resize=1024x768",
        id:2,
        price:"AOA 47.999",
        level:2,
      },
      {
        status:0,
        name:"VirtuFit plataforma de videoconferencia com React.js Node.js, Mongodb ...",
        image:"https://cdn.dribbble.com/userupload/8600716/file/original-b7cca116f535223cd7ecf2877a7915f4.jpg?resize=1024x730",
        id:3,
        price:"AOA 23.999",
        level:1,
      },
      {
        status:0,
        name:"Blog de Noticias com React.js, Javascript, Node.js, Mysql, Express.js, Material Ui...",
        image:"https://cdn.dribbble.com/userupload/3254197/file/original-2f14947dad1cc56d4327e09db15ca682.png?resize=1024x768",
        id:4,
        price:"AOA 18.000",
        level:1,
      },

      {
        status:0,
        name:"Aplicativo de streaming de animes , on/off com React-Native, MongoDB, Node.js,  Expo ....",
        image:"https://images.ui8.net/uploads/preview-5_1655635262113.png",
        id:5,
        price:"AOA 12.000",
        level:1,
      },
      {
        status:0,
        name:"Aplicativo imobiliario, compra e aluguel de imoveis com React-Native, Mysql, Node.js , Expo...",
        image:"https://cdn.dribbble.com/userupload/10167988/file/original-2f0fb8c517b80d3dc0210da949472b57.png?resize=1024x768",
        id:6,
        price:"A0A 13.999",
        level:1,
      },
      {
        status:0,
        name:"Portal de empregos com Html5, css, Javascript, Node.js, Ajax, Mysql, Express.js ...",
        image:"https://cdn.dribbble.com/users/1682902/screenshots/8379991/media/82e7e7fc0b08896971bbab970d15d540.png?resize=768x576&vertical=center",
        id:7,
        price:"AOA 8.000",
        level:1,
      },
      {
        status:0,
        name:"Design de site empresarial com bootstrapp, Css, Html5, Javascript ",
        image:"https://cdn.dribbble.com/userupload/9199587/file/original-fd47485f32c7acbba93c542e8e437172.png?resize=1024x768",
        id:8,
        price:null,
        level:1,
      },
      {
        status:0,
        name:"Design de site empresarial (Industrial) com bootstrapp, Css, Html5, Javascript",
        image:"https://cdn.dribbble.com/userupload/4059394/file/original-d008bcb9b86a62bbb466a81435e19186.png?resize=1024x768",
        id:9,
        price:null,
        level:1,
      },
  ]


  return (
    <div className='courses-page'>
      <Cnavbar />
      <section>
        <div className="wrapper">
          <br />
        <Alert  variant="warning"> 
            <p>
               Devido algumas atualizações que estão a ser efectuadas no website, algumas funcionalidade e cursos ficarão temporariamente
               Inactivados até o final de Junho. Agradecemos a sua comprenção. <strong>Para mais informações contactar o administrador do site 
                atravez do seguinte whatsapp : +351 964 162 609</strong>
            </p> 
        </Alert>
          <div className="title">Cursos em destaque na academia <span className="text-main">({Data.length})</span> </div>
          <section className="courses">
            {Data.map((item, index) => {
              return (
                <article className="course-box">
                    <Link to={`/academy_course_view?code=${item.id}`}> 
                        <ImageLoading source={item.image} height={280} />                    
                    </Link>
                  <div className="dets">
                     <div className="badge bg-info mt-2 level">Iniciante</div>
                     <Link to={`/academy_course_view?code=${item.id}`}><div className="text-dark"><h5>{item.name}</h5></div></Link>
                     <span className="status text-warning">Em lançamento</span>
                     <br />
                     <strong className="price">{item.price !== null ? item.price : <span className='text-success'>Gratuito</span>  }</strong><br />
                    {item.status*1 === 1 ? 
                     <Link to={`/academy_course_view?code=${item.id}`}>
                        <button className='btn text-light bg-main'> <strong>Começar Agora</strong></button> 
                     </Link>:
                     <button className='btn text-light bg-warning'><strong>Em lançamento</strong></button>}
                  </div>
                </article>
              )
            })}
          </section>
        </div>
        <br />   <br />   <br /> 
      </section>
    </div>
  )
}

export default Courses
