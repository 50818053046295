import React, { useState } from 'react' 
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom' 
import ModalData from './ModalData';
import ImageLoading from './ImageLoading';
import { FcDribbble } from "react-icons/fc";

 

const items = [
  {
    image:require('../Images/Projects/news.png'), 
    video_code:'', 
    dribbble:null,
    building:true,
    title:'News Reporting website',  
    link:'https://newsreportingkc.vercel.app',  
    description:'News reporting is a website information television stations, founded in 2024 and based in Lyon, France, Africa.', 
    skills:["Next.js", "Bootstrap", "Css", "MongoDB", "Java", "SpringBoot", "GCP"]
  }, 
  {
    image:require('../Images/Projects/social.png'), 
    video_code:'',  dribbble:null,
    building:true,
    category:{
      name:"Mobile application",
      link:"#"
  },
    title:'Athena Social network app',  
    link:'https://socialnetkc.vercel.app',  
    description:'Athena is a social structure made up of people or organizations, connected by one or several types of relationships, who share common values ​​and objectives.', 
    skills:["React-Native", "Socket.io", "Firebase", "Firestore", "Node.js", "Express.js", "GCP"]
  }, 
  {
    image:require('../Images/Projects/office.jpg'), 
    video_code:'', 
    dribbble:null,
    building:true, 
    category:{
      name:"Mobile application",
      link:"#"
    },
    title:'Microsoft office (8 apps redesign)',  
    link:'https://officekc.vercel.app',  
    description:'I created a redesign of the best Microsoft office (Excel, PowerPoint, Word, Outlook, OneNote, OneDrive, Teams, Skype)', 
    skills:["Firebase", "SpringBoot", "Java", "MongoDB", "GCP services", "Typescript", "Sockets", "WEBRTC", "React-Native"]
  }, 
  {
    image:require('../Images/Projects/estate.png'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'Kinghomes website',  
    link:'https://kinghomes.vercel.app', 
    description:'For those who seek an exceptional home and life, there is only KingHomes International Realty. Built on centuries of tradition and love.', 
    skills:["React.js", "MongoDB", "CSS", "Express.js", "Node.js"]
  }, 
    {
      image:require('../Images/Projects/euronews_app.jpeg'), 
      video_code:'',  dribbble:null,
      building:false,
      category:{
          name:"Mobile application",
          link:"#"
      },
      title:'Euronews app redesign',  
      link:'https://youtu.be/D-B5ifewgfM',  
      description:'Euronews is a network of pan-European information television stations, founded in 1993 and based in Lyon, France.', 
      skills:["React-Native", "Axios", "Asyncstorage", "APIS", "Tailwind Css"]
    }, 
    {
      image:require('../Images/Projects/google.webp'), 
      video_code:'',  dribbble:null,
      building:true, 
      top:true,
      title:'Google ( 10 apps redesign)',  
      link:'https://googlekc.vercel.app',  
      description:'I created a redesign of the best Google software (Drive, Docs, Meets, GCP, Translate, News, Calendar, Photos, Gmail, Search)', 
      skills:["Firebase", "NextJs", "SpringBoot", "Java", "MongoDB", "GCP services", "Typescript", "Sockets", "WEBRTC", "ReactJs"]
    }, 
    
    {
      image:require('../Images/Projects/Moomo.jpg'), 
      video_code:'',  dribbble:null,
      building:true,
      category:{
          name:"Mobile application",
          link:"#"
      },
      title:'Moomo  ( tv shows and animes app)',  
      link:'https://moomotv.vercel.app',  
      description:'Moomo is an international app focused on video streaming of East Asian media, including anime, manga, drama, electronic entertainment and content.', 
      skills:["React-Native", "SpringBoot", "Java", "MongoDB", "GCP services", "Typescript"]
    }, 
    {
      image:require('../Images/Projects/wizashop_web.png'), 
      video_code:'',  dribbble:null, 
      building:true,
      title:'Wizashop ecommerce',  
      link:'https://wizashopweb.vercel.app/',  
      description:'Wizashop is an e-commerce platform owned by kiosso camuegi, which is a global consumer Internet business founded', 
      skills:["Next.js", "Node.js", "Express.js", "APIS" , "Next.js Auth", "Redux", "MongoDB"]
    },  
    
  {
    image:require('../Images/Projects/movie.png'), 
    video_code:'',  dribbble:null,
    category:{
      name:"Mobile application / Web",
      link:"#"
  },
    title:'SaintStream TV ( WEB & Mobile App )', 
    building:true,
    link:'https://saintstreamtv.vercel.app', 
    description:'Enjoy more then +10.000 movies and tv shows without paying anything and download if you want to save them in your device.', 
    skills:["React.js", "Animate.css", "Redux", "Java", "React-Native", "Mongodb", "SpringBoot"]
  },  
  
    {
      image:require('../Images/Projects/zuzu.png'), 
      video_code:'',  dribbble:null,
      category:{
        name:"Mobile application",
        link:"#"
    },
      building:true,
      title:'Zuzu short video social app', 
      link:'https://zuzu9.vercel.app',
      description:'It is a leading short video platform in Asia, the United States and other parts of the world. The application gained popularity and became the most downloaded in recent times.', 
      skills:["React-Native", "Firebase", "Tailwind", "Node.js", "Express.js", "REST APIS"]
    }, 
  {
    image:require('../Images/Projects/hichat.png'), 
    video_code:'',  dribbble:null,
     building:true,
    category:{
      name:"Mobile application",
      link:"#"
  },
    title:'Hichat messenger app',  
    link:'https://hichatweb.vercel.app',  
    description:'Hichat is an instant messenger and application that provides text and video communication and is developed and published by kiosso camuegi', 
    skills:["React-Native", "Express.js",  "Material Ui", "MongoDB", "Socket.io"]
  },  
    {
      image:require('../Images/Projects/farfetech.png'), 
      video_code:'',  dribbble:null,
      category:{
        name:"Mobile application / Web",
        link:"#"
      },
      title:'Farfetch startup redesign (Web / App)', 
      building:true,
      link:'https://farfetch9.vercel.app', 
      description:"Discover Daily New Arrivals From The Latest SS24 Collections Today.  The World's Best Fashion Edits.", 
      skills:["AsyncStorage", "React-Native", "Redux", "APIS", "Java", "Redux", "Next.Js", "MongoDb"]
    }, 
    {
      image:require('../Images/Projects/bank.jpg'), 
      video_code:'',  dribbble:null,
      building:false,
      title:'Standard Bank Website redesign',  
      link:'https://standardbankkiossoredesign.vercel.app',  
      description:'Standard Bank Group Limited is one of the largest financial services groups in South Africa. It operates in 38 countries, of which 18 are in Africa.', 
      skills:["React.js", "Bootstrap", "Css3", "Styled-components", "APIS"]
    }, 
  {
    image:require('../Images/Projects/pizza.png'), 
    video_code:'',  dribbble:null,
    building:true,
    title:'Pizza website design', 
    link:'https://pizzakcp990.vercel.app',
    description:'All rights reserved. This work may not be translated or copied in whole or in part without the written permission of the publisher.', 
    skills:["React.js", "Css3", "Styled components"]
  }, 
  
    {
      image:require('../Images/Projects/belibeli.png'), 
      video_code:'',  dribbble:null,
      building:true,
      title:'BeliBeli.com ecommerce', 
      link:'https://bebibeli.vercel.app',
      description:'BeliBeli is an online ecommerce website that allow users to buy products without leaving the sofa. ', 
      skills:["React.js", "Redux", "Node.js", "Express.js", "MongoDB"]
    }, 

   
  {
    image:require('../Images/Projects/bible.png'), 
    video_code:'',  dribbble:null,
    category:{
      name:"Mobile application",
      link:"#"
    },
    title:'Bibble App (Multilanguage)', 
    building:true,
    link:'https://bibble.vercel.app', 
    description:'The Bible is an anthology of religious texts or sacred scriptures for Christianity, Judaism, Samaritanism, and many other religions.', 
    skills:["AsyncStorage", "React-Native", "Redux", "APIS"]
  },  
  {
    image:require('../Images/Projects/recipe.png'), 
    video_code:'',  dribbble:null,
    title:'RecipeLove website design', 
    building:false,
    link:'https://recipelove.vercel.app', 
    description:'RecipeLove is a trusted resource for home cooks with more than 3,000 tested recipes, guides, and meal plans, drawing over 15 million readers each month from around the world.', 
    skills:["React.js", "Animate.css", "Css"]
  },  
 
  {
    image:require('../Images/Projects/handyman.jpg'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'Handyman website',  
     link:' https://kiossocamuegi.github.io/handyman/', 
    description:'All large companies have  a large website and in this case Handyman is the perfect example for a Construction website design.', 
    skills:["Html", "Javascript", "Css"]
  },  
     {
    image:require('../Images/Projects/Hightech.png'), 
    video_code:'',  dribbble:null,
    title:'Hightech website', 
    building:false,
    link:'https://www.hightech-airer.pt', 
    description:'With over 13 years of experience, Hightech-AIRER, located in Moita, Setúbal, is a company specialized in various industrial and domestic solutions.', 
    skills:["React.js", "Animate.css", "Css", "Javascript"]
  },  
  {
    image:require('../Images/Projects/fc.png'), 
    video_code:'',  dribbble:null,
    title:'Future Healthcare website redesign', 
    building:false,
    link:'https://futurehealthcare.vercel.app/', 
    description:'The Future HealthCare Group is a company specializing in the development and management of medical service plans, health insurance and other health systems.', 
    skills:["React.js", "Css", "Javascript"]
  },  
  {
    image:require('../Images/Projects/sonangol.png'), 
    video_code:'',  dribbble:null,
    title:'Sonangol website redesign',  
    building:false,
    link:'https://sonangolwebsite.vercel.app',  
    description:'Sonangol is a state-owned oil category, responsible for the administration and exploration of oil and natural gas in Angola.', 
    skills:["Html5", "Bootstrap", "Css","Javascript"]
  }, 
  {
    image:require('../Images/Projects/smallapps.jpg'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'Small Apps website', 
    link:'https://kiossocamuegi.github.io/smallapps/', 
    description:'Showcase your app with Small Apps,  Besides its beautiful design. Laapp is an incredibly rich core framework for you to showcase your App.', 
    skills:["Html", "Javascript", "Css"]
  },  
  {
    image:require('../Images/Projects/car.jpg'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'2D Desert racing car Game', 
    link:'https://kiossocamuegi.github.io/wizacar',
    description:'All rights reserved. This work may not be translated or copied in whole or in part without the written permission of the publisher.', 
    skills:["Html", "Css3", "Javascript"]
  },
  {
    image:require('../Images/Projects/builders.jpg'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'Builders website design', 
    link:' https://kiossocamuegi.github.io/builders/', 
    description:'A simple but handsome landing page design for small companies is all you need when starting a Hustle', 
    skills:["Html", "Javascript", "Css"]
  }, 
  {
    image:require('../Images/Projects/hungrylion.jpg'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'Hungry lion website redesign', 
    link:'https://kiossocamuegi.github.io/hungrylion/', 
    description:'We all have a hungry lion inside us, and good food comes from the best websitesz and one of them is the hungrylion website redesign', 
    skills:["Html", "Javascript", "Css"]
  }, 
  {
    image:require('../Images/Projects/microsoftexcel.jpg'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'Microsoft excel (website version)', 
    link:' https://kiossocamuegi.github.io/microsoftexcelclone/', 
    description:'Microsoft excel is a wonderful platform, imagine what it would be like to use this amazing software in a browser, ready here it is.', 
    skills:["Html", "Javascript", "Css"]
  },
  {
    image:require('../Images/Projects/accesslite.jpg'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'ACCESSELITE website design', 
    link:'https://kiossocamuegi.github.io/ACCESSELITE/', 
    description:'Accesslite is simple but a nice healthcare website design for your love ones, take  a look and see some magic .', 
    skills:["Html", "Javascript", "Css"]
  }, 
  {
    image:require('../Images/Projects/bureua.jpg'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'Bureua website design', 
    link:'https://kiossocamuegi.github.io/bureua/', 
    description:'Bureua is simple but a nice team website design for your team, take  a look and see the power of tech in action.', 
    skills:["Html", "Javascript", "Css"]
  }, 
  {
    image:require('../Images/Projects/schedule.jpg'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'Schedule web application', 
    link:'https://scheduleapp.vercel.app/', 
    description:'schedule is a system for counting and grouping days that aims to meet mainly civil needs, and corporate employees.', 
    skills:["Html", "Javascript", "Css", "Java", "SpringBoot"]
  }, 
  {
    image:require('../Images/Projects/ekhavil.jpg'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'Ekhavil website', 
    link:'https://ekhavil.com', 
    description:'ekhavil is one of the best real estate companies in angola, and I had the honor of helping with the creation of the website, namely the dashboard', 
    skills:["Html", 'Php', 'Jquery', 'API', "Javascript", "Css"]
  }, 
  {
    image:require('../Images/Projects/bns.png'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'Bynary website design',  
    link:'https://kiossocamuegi.github.io/bns/', 
    description:'Bynary considers every little detail to ensure that the system run smoothly and responsively.', 
    skills:["Html", "Javascript", "Css"]
  },
  {
    image:require('../Images/Projects/finger.jpg'), 
    video_code:'',  dribbble:null,
    building:true,
    title:'Landing page website design', 
    link:'https://fingerprint.vercel.app',
    description:'All rights reserved. This work may not be translated or copied in whole or in part without the written permission of the publisher.', 
    skills:["Html", "Css3", "Javascript"]
  }, 
  {
    image:require('../Images/Projects/hosting.jpg'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'Hosting website design',  
    link:'https://kiossocamuegi.github.io/Serverservicewebsite/', 
    description:'hosting is a wonderful hosting website with a unique interface created with the best stacks out there. the website has more than 4 awesome pages.', 
    skills:["Html", "Javascript", "Css"]
  }, 
  
  {
    image:require('../Images/Projects/netflix.png'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'Netflix website design', 
    link:'https://netflix-sable-six.vercel.app/',
    description:"We all like to make some famous website clones, i do , you do , the all worl like to do websites clones. (2020 project)", 
    skills:["Html", "Css3", "Javascript"]
  }, 

  {
    image:require('../Images/Projects/portofolio.jpg'), 
    video_code:'',  dribbble:null,
    building:false,
    title:'Portofolio website design', 
    link:'https://kiossocamuegi.github.io/portofoliodesign/',
    description:"There is no better way to demonstrate your skills than by creating projects, that's why jsy has a portfolio", 
    skills:["Html", "Css3", "Javascript"]
  }, 
];


function Projects() {
  return (
    <div className='projects' id='projects'>
        <div className="wrapper">
          <div className="space">
          <div className="title text-right">
              <h2>04. <span>scandalous projects <small>({items.length})</small> </span></h2>
          </div>
          <Link to="/resume"><div className="link">Open Resume</div></Link>
          </div>
          <section>            
             <PaginatedItems itemsPerPage={6} />
          </section> 
        </div>
     </div>
  )
}

function Items({currentItems}){
  return( 
    <section className="projects-row">  
     {currentItems &&
      currentItems.map((item)=>(
      <article video_code={item.video_code} className="project-card">
          <div className="card-data">
          <div className="image"> 
          {item.category ?  
           <a href={item.category.link}  rel="noreferrer" target='_blank'>
               <div className="badge bg-warning text-dark">
                {item.category ? item.category.name : ""}
                
            </div>
           </a> 
          : ""} 
           {item.top ?  
           <a  rel="noreferrer" target='_blank'>
               <div className="badge bg-info text-dark">
                {item.top ? "Best project created": ""}
                
            </div>
           </a> 
          : ""} 
          <a href={item.link}  rel="noreferrer" target='_blank'> 
              <ImageLoading  source={item.image} height={245} />   
           </a> 
            </div>
            <a href={item.link}  rel="noreferrer" target='_blank'>  <h3 className="title">{item.title}</h3> </a> 
           
            <p>{item.description}</p>
            <div className="skills-box">
              {item.skills.map((el, i)=>{
                return <div className="skill" key={i}><div className="dot"></div> {el}</div>
              })}
            </div>
           <div className="flex f1">
           <ModalData toggle_btn={
                <a  rel="noreferrer" target='_blank' href={item.link}><button className='btn'>Take a look</button></a>
             }/>
               {item.building === true ? <div className='flex'><div className="blob"></div> on development</div>  : <></>}
           </div>
          </div>
        </article> 
      ))
      }
   </section> 
  )
}

function PaginatedItems({ itemsPerPage }) {  
  const [itemOffset, setItemOffset] = useState(0); 
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage); 
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length; 
    setItemOffset(newOffset);
  };


  return (
    <>
      <Items currentItems={currentItems} />
     <div className="pag">
     <ReactPaginate
        breakLabel="..."
        nextLabel=" > "
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel=" < "
        renderOnZeroPageCount={null}
      />
     </div>
    </>
  );
}

 
export default Projects
