import React from 'react'
import Cnavbar from './components/Cnavbar';
import { Link } from 'react-router-dom';
import { BiLock } from 'react-icons/bi';
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';


function CourseView() {
    const [show, setShow] = useState(true);


    return (
        <div className='courses-page dets'>
            <Cnavbar />
            <div className="wrapper">
                <br />
                <h5 className='ct-title'><Link to="/academy" className='text-main' >Cursos /  </Link> Portofolio Design com Html, Css, Javascript, Bootstrap</h5>
                <div className="course-dets">
                    <aside className="content-area">
                        <section>
                            <div className="video-area">
                                <div className="player">
                                <iframe src="https://drive.google.com/file/d/1mECe0ywdz1I7q28eGCE1du0G-_KVQ2gE/preview"  
                                 allow="autoplay"></iframe>
                                </div>
                                <br />
                                <h3 className='lesson-title'>Nome da aula atual que esta  a ser assistida</h3>
                            </div>
                        </section>
                        <section >
                            <ul className="tabs">
                                <li className='active'>Descrição</li>
                                <li><BiLock /> Ficheiros do projeto</li>
                                <li><BiLock /> Forum de discuções</li>
                                <li><BiLock /> Feedbacks</li>
                            </ul>
                            <div className="description-block">
                                <aside className='active'>
                                    <h1 className='lesson-title'>Visão Geral do Curso</h1>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quos, commodi molestiae cum doloribus quas assumenda repudiandae quidem saepe recusandae accusamus libero? Pariatur rerum aperiam architecto inventore quidem? Ab, itaque!</p>
                                    <br />
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla similique voluptates deleniti, non molestiae sit officiis, accusantium minima debitis cumque eligendi corporis veritatis deserunt consequatur rerum eveniet
                                        necessitatibus neque atque voluptatum ad, consectetur modi a. Fugit nobis est in ea?</p>
                                    <br />

                                    <Alert show={show} variant="info">
                                        <Alert.Heading>My Alert</Alert.Heading>
                                        <p>
                                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget
                                            lacinia odio sem nec elit. Cras mattis consectetur purus sit amet
                                            fermentum.
                                        </p> 
                                    </Alert>

                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, 
                                    est. Asperiores rerum aspernatur dolore magni debitis, ipsum incidunt cum maiores iusto impedit quo temporibus mollitia vero, aut qui dicta, recusandae exercitationem minima dolores id explicabo! Unde iure, culpa sed rerum maxime corporis optio repellat quo veritatis voluptatum porro assumenda! Consectetur sed fugiat sunt. Atque, id odit quisquam odio incidunt neque!</p>
                                </aside>
                                <aside></aside>
                                <aside></aside>
                                <aside></aside>
                            </div>
                        </section>
                    </aside>
                    <aside className="playlist">
                        <div className="play-header">
                              <h5>Playlist do Curso (6)</h5>
                        </div>
                        <ol>
                            <div className="header-title"> Estruturação do projecto</div>
                            <li>Extruturação do projecto</li>
                            <li>Como usar as fontes e Icones</li>
                            <li>Design da pagina principal</li>
                        </ol>
                        <ol>
                            <div className="header-title">Optimização & Ajustes</div>
                            <li>Adaptação Mobile</li>
                            <li>Salvar projeto no Github</li>
                            <li>Hospedar o site em vercel</li>
                        </ol>
                    </aside>
                </div>
            </div>
        </div>
    )
}

export default CourseView
