import React from 'react' 
import {TbBrandPython ,TbBrandTailwind, TbCurrencySolana, TbJson, TbBrandPhp, TbApi, TbCurrencyEthereum,TbBrandBootstrap,
 TbBrandMongodb, TbBrandJavascript,TbBrandSass, TbBrandNextjs, TbBrandFirebase,TbBrandGit, TbBrandRedux, TbBrandGithub, TbBrandCss3,TbBrandFlutter, TbBrandHtml5, TbBrandReactNative} from "react-icons/tb";
import {FaNodeJs} from "react-icons/fa";
import {BiLogoPostgresql, BiLogoJquery} from "react-icons/bi";
import { SiMysql, SiAdobexd } from "react-icons/si";
import { Link } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaCloudflare } from "react-icons/fa6";
import { TbBrandTypescript } from "react-icons/tb";
import { LiaDocker } from "react-icons/lia";
import { TbBrandCSharp } from "react-icons/tb";
import { FaUnity } from "react-icons/fa";
import { FaAws } from "react-icons/fa6";
import { SiSocketdotio } from "react-icons/si";
import { SiGooglecloud } from "react-icons/si";
import { IoHardwareChipOutline } from "react-icons/io5";
import { BiLogoSpringBoot } from "react-icons/bi";
import { FaJava } from "react-icons/fa6";
import { SiNestjs } from "react-icons/si";
import { SiPostman } from "react-icons/si";
import { SiFlask } from "react-icons/si";


function Skills(props) {
  const SkillsData = [
     {skill_icon:<TbBrandNextjs/>, skill_title:'NextJS'},
     {skill_icon:<TbBrandCss3/>, skill_title:'Css3'},
     {skill_icon:<TbBrandHtml5/>, skill_title:'Html5'},
     {skill_icon:<TbBrandJavascript/>, skill_title:'Javascript'},

     {skill_icon:<TbBrandReactNative/>, skill_title:'React Native'},
     {skill_icon:<BiLogoJquery/>, skill_title:'Jquery'},
     {skill_icon:<TbBrandGit/>, skill_title:'Git'},
     {skill_icon:<TbBrandGithub/>, skill_title:'Github'},

     {skill_icon:<TbBrandRedux/>, skill_title:'Redux'},
     {skill_icon:<TbBrandTailwind/>, skill_title:'Tailwind'},
     {skill_icon:<TbBrandSass/>, skill_title:'Sass'},
     {skill_icon:<TbBrandFirebase/>, skill_title:'Firebase'},

     {skill_icon:<BiLogoPostgresql/>, skill_title:'PostgreSQL'},
     {skill_icon:<SiMysql/>, skill_title:'Mysql'}, 
     {skill_icon:<BiLogoSpringBoot/>, skill_title:'SpringBoot'},

 
     {skill_icon:<SiAdobexd/>, skill_title:'Adobe XD'},
     {skill_icon:<TbBrandPhp/>, skill_title:'PHP'}, 

     {skill_icon:<TbBrandMongodb/>, skill_title:'MongoDB'},
     {skill_icon:<FaJava />, skill_title:'Java (Backend development)'}, 
     {skill_icon:<FaNodeJs />, skill_title:'Node.js'}, 

     {skill_icon:<SiFlask/>, skill_title:'Python flask'},
     {skill_icon:<FaCloudflare />, skill_title:'Cloudflare'}, 
     {skill_icon:<TbBrandTypescript />, skill_title:'Typescript'},  


     {skill_icon:<LiaDocker/>, skill_title:'Dockers'},
     {skill_icon:<SiPostman />, skill_title:'Postman'}, 
     {skill_icon:<FaUnity />, skill_title:'Unity'},  

     {skill_icon:<FaAws  />, skill_title:'AWS'},  
     {skill_icon:<SiSocketdotio />, skill_title:'Socket.io'},  
     {skill_icon:<SiGooglecloud />, skill_title:'Google Cloud (console)'}, 
     {skill_icon:<IoHardwareChipOutline />, skill_title:'Hardware & Software'},  

  ];

  const GetColor = ()=>{
       if(props.scolor){
          return props.scolor ? props.scolor : props.color;
       }else if(props.color){
          return props.color ? props.color : 'black';
       }else{
          return 'var(--pm-color)';
       }
  }


 
  return (
    <div className='skills' id='skills' 
    style={{background:`${props.color ? props.color : 'var(--pm-color)'}`,
     backgroundBlendMode:`${props.blend ? props.blend : ''}` }} >
    <div className="wrapper">
        <div className="space">
        <div className="title text-right">
             <h2>03. <span>Skills that i aquired</span></h2>
         </div>
        <Link to="/resume"><div className="link">Open Resume</div></Link>
        </div>
         <div className="skills-row">
         <div class="main">
            <div class="container"> 
               {SkillsData.map((item , index)=>{
                    return( 
                     <OverlayTrigger key={index}  placement="right" delay={{ show: 250, hide: 400 }}   overlay={
                         <Tooltip id="button-tooltip">{item.skill_title}</Tooltip>}>
                          <div key={index} className="skill-row-item-shape">
                             <div className="ct" style={{background:`${GetColor()}`}} >
                                  {item.skill_icon}
                             </div>
                        </div>  
                     </OverlayTrigger> 
                    )
               })}9991 0653  3224 4
              </div>
            </div>
         </div>
    </div>
  </div>
  )
}

export default Skills
